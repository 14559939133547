<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine the molecular geometry around each of the labelled "central" atoms:
      </p>

      <img src="/img/assignments/VSEPR1.png" width="283" height="253" class="ml-8 mb-5" />

      <template v-for="(part, i) in parts">
        <p :key="part">
          <stemble-latex :content="`$\\text{${part.toLowerCase()}) Atom ${part}:}$`" />
        </p>

        <v-radio-group :key="part" v-model="inputs[`input${i + 1}`]" class="pl-8">
          <v-radio v-for="option in options" :key="option.value" :value="option.value">
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </template>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

const LINEAR = 'a';
const BENT = 'b';
const TRIGONAL_PLANAR = 'c';
const TRIGONAL_PYRAMIDAL = 'd';
const TETRAHEDRAL = 'e';
const SQUARE_PLANAR = 'f';

export default {
  name: 'Question43',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      parts: ['A', 'B', 'C', 'D'],
      options: [
        {text: 'Linear', value: LINEAR},
        {text: 'Bent', value: BENT},
        {text: 'Trigonal Planar', value: TRIGONAL_PLANAR},
        {text: 'Trigonal Pyramidal', value: TRIGONAL_PYRAMIDAL},
        {text: 'Tetrahedral', value: TETRAHEDRAL},
        {text: 'Square Planar', value: SQUARE_PLANAR},
      ],
    };
  },
};
</script>
